import './App.css';
import Header from './components/header';
import Content from './components/content';
import { Main, Page, Theme, Navigation} from '@yakad/ui';


function App() {
  return (
    <Theme mode='light' color='grow'>
      <Page>
        <Header />
        <Main navOpen={true}>
          <h2>All Products</h2>
          <Content />
        </Main>
        <Navigation anchor='auto' open={true}>
          <ul>
            <li>Category</li>
            <li>Model</li>
            <li>Other</li>
          </ul>
        </Navigation>
      </Page>
    </Theme>
  );
}

export default App;
