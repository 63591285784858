import { Card, Button } from "@yakad/ui";
import primage from '../assets/jpg/gearbox.jpeg'

function Product() {
    return (
      <Card>
        <img style={{maxWidth: "100%"}} src={primage} />
        <h3>AL4 Gearbox</h3>
        <h4>Price:1000$</h4>
        <Button variant="filled" size="small">Add To Cart</Button>
      </Card>
    );
  }
  export default Product;