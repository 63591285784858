import {Page, GridContainer, GridItem, Container} from '@yakad/ui';
import Product from './Product';

function Content() {
    return (
          <Container style={{background: "gray"}} maxWidth='sm'>
            <GridContainer rowGap={1} columnGap={1}>
              <GridItem xl={6} sm={12}>
                <Product />
              </GridItem>
              <GridItem xl={6} sm={12}>
                <Product />
              </GridItem>
              <GridItem xl={6} sm={12}>
                <Product />
              </GridItem>
              <GridItem xl={6} sm={12}>
                <Product />
              </GridItem>
            </GridContainer>
          </Container>
      );
    
}
export default Content;