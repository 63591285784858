import { AppBar, Button, Spacer} from '@yakad/ui';
import logo from '../assets/jpg/AHAmech colores main.png';

function Header() {
    return (
      <AppBar style={{position: "sticky", top: 0}}>
        <img src={logo} alt='logo' width={80}/>
        <Spacer />
        <Button size='small' variant='filled'>Sign Up</Button>
      </AppBar>
    );
  }

export default Header;
